import {
  SET_MESSAGE_KEYWORD,
  CHAT_USER,
  ACTIVE_USER,
  ACTIVE_USER_TEMP,
  FULL_USER,
  ADD_LOGGED_USER,
  CREATE_GROUP,
  SET_CASE_LIST,
  SET_CONTACT_LIST,
  SET_MEMBER_INFO,
  SET_LOAD_MESSAGE,
} from "./constants";

export const chatUser = () => ({
  type: CHAT_USER,
});

export const activeUser = (userId) => ({
  type: ACTIVE_USER,
  payload: userId,
});

export const activeUserTemp = (userId) => ({
  type: ACTIVE_USER_TEMP,
  payload: userId,
});

export const setMemberInfo = (memberInfo) => ({
  type: SET_MEMBER_INFO,
  payload: memberInfo,
});

export const setMessageKeyword = (messageKeyword) => ({
  type: SET_MESSAGE_KEYWORD,
  payload: messageKeyword,
});

export const setFullUser = (fullUser) => ({
  type: FULL_USER,
  payload: fullUser,
});

export const addLoggedinUser = (userData) => ({
  type: ADD_LOGGED_USER,
  payload: userData,
});

export const createGroup = (groupData) => ({
  type: CREATE_GROUP,
  payload: groupData,
});

export const setCaseList = (caseList) => ({
  type: SET_CASE_LIST,
  payload: caseList,
});

export const setContactList = (contactList) => ({
  type: SET_CONTACT_LIST,
  payload: contactList,
});

export const setLoadMessage = (data) => ({
  type: SET_LOAD_MESSAGE,
  payload: data,
});
