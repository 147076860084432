import {
  SET_MESSAGE_KEYWORD,
  CHAT_USER,
  ACTIVE_USER,
  ACTIVE_USER_TEMP,
  FULL_USER,
  ADD_LOGGED_USER,
  CREATE_GROUP,
  SET_CASE_LIST,
  SET_CONTACT_LIST,
  SET_MEMBER_INFO,
  SET_LOAD_MESSAGE,
} from "./constants";

//Import Images
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar6 from "../../assets/images/users/avatar-6.jpg";
import avatar7 from "../../assets/images/users/avatar-7.jpg";
import avatar8 from "../../assets/images/users/avatar-8.jpg";
import img6 from "../../assets/images/small/img-6.jpg";

const INIT_STATE = {
  loadMessage: {},
  messageKeyword: "",
  active_user: -1,
  active_user_temp: "",
  users: [
    //admin is sender and user in receiver
    {
      id: 0,
      name: "",
      profilePicture: "Null",
      messages: [],
    },
  ],
  memberInfo: [],
  users2: [
    {
      id: 13,
      name: "General",
      profilePicture: "Null",
      unRead: 0,
      isGroup: true,
      messages: [
        { id: 33, isToday: true },
        {
          id: 1,
          userName: "John Smith",
          message: "Hello send project images",
          time: "12:00",
          userType: "receiver",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 2,
          userName: "Steve Walker",
          message: "Images",
          time: "12:05",
          userType: "receiver",
          isImageMessage: true,
          isFileMessage: false,
          imageMessage: [{ image: img6 }],
        },
        {
          id: 3,
          userName: "admin",
          message: "Good Afternoon everyone !",
          time: "2:05",
          userType: "sender",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 4,
          userName: "Galen Rizo",
          message: "This theme is Awesome!",
          time: "2:06",
          userType: "receiver",
          isImageMessage: false,
          isFileMessage: false,
        },
      ],
    },
    {
      id: 5,
      name: "Designer",
      profilePicture: "Null",
      unRead: 1,
      isGroup: true,
      messages: [
        {
          id: 1,
          userName: "Doris Brown",
          message: "Hello send project images",
          time: "12:00",
          userType: "receiver",
          isImageMessage: false,
          isFileMessage: false,
        },
        { id: 33, isToday: true },
        {
          id: 2,
          userName: "Steve Walker",
          message: "Images",
          time: "12:05",
          userType: "receiver",
          isImageMessage: true,
          isFileMessage: false,
          imageMessage: [{ image: img6 }],
        },
        {
          id: 3,
          userName: "admin",
          message: "Images",
          time: "01:30",
          userType: "sender",
          isImageMessage: false,
          isFileMessage: true,
          fileMessage: "Minible-Vertical.zip",
        },
        {
          id: 4,
          userName: "admin",
          message:
            "@Doris Brown please review this code, and give me feedback asap",
          time: "01:31",
          userType: "sender",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 5,
          userName: "John Howard",
          message: "Good Afternoon everyone !",
          time: "2:00",
          userType: "receiver",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 6,
          userName: "admin",
          message: "Good Afternoon everyone !",
          time: "2:05",
          userType: "sender",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 9,
          userName: "John Howard",
          message: "Next meeting tomorrow 10.00AM",
          time: "2:10",
          userType: "receiver",
          isImageMessage: false,
          isFileMessage: false,
        },
      ],
    },
    {
      id: 3,
      name: "Ossie Wilson",
      profilePicture: avatar3,
      status: "away",
      unRead: 0,
      isGroup: false,
      messages: [
        { id: 33, isToday: true },
        {
          id: 1,
          message: "hi",
          time: "12:00",
          userType: "sender",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 2,
          message: "Did you finished it?",
          time: "12:00",
          userType: "sender",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 2,
          message: "I've finished it! See you so",
          time: "12:05",
          userType: "receiver",
          isImageMessage: true,
          isFileMessage: false,
          imageMessage: [{ image: img6 }],
        },
      ],
    },

    {
      id: 14,
      name: "Designer",
      profilePicture: "Null",
      unRead: 1,
      isGroup: true,
      messages: [
        {
          id: 1,
          userName: "Doris Brown",
          message: "Hello send project images",
          time: "12:00",
          userType: "receiver",
          isImageMessage: false,
          isFileMessage: false,
        },
        { id: 33, isToday: true },
        {
          id: 2,
          userName: "Steve Walker",
          message: "Images",
          time: "12:05",
          userType: "receiver",
          isImageMessage: true,
          isFileMessage: false,
          imageMessage: [{ image: img6 }],
        },
        {
          id: 3,
          userName: "admin",
          message: "Images",
          time: "01:30",
          userType: "sender",
          isImageMessage: false,
          isFileMessage: true,
          fileMessage: "Minible-Vertical.zip",
        },
        {
          id: 4,
          userName: "admin",
          message:
            "@Doris Brown please review this code, and give me feedback asap",
          time: "01:31",
          userType: "sender",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 5,
          userName: "John Howard",
          message: "Good Afternoon everyone !",
          time: "2:00",
          userType: "receiver",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 6,
          userName: "admin",
          message: "Good Afternoon everyone !",
          time: "2:05",
          userType: "sender",
          isImageMessage: false,
          isFileMessage: false,
        },
        {
          id: 9,
          userName: "John Howard",
          message: "Next meeting tomorrow 10.00AM",
          time: "2:10",
          userType: "receiver",
          isImageMessage: false,
          isFileMessage: false,
        },
      ],
    },
  ],
  groups: [
    {
      gourpId: 1,
      name: "#General1",
      profilePicture: "Null",
      isGroup: true,
      unRead: 0,
      desc: "General Group",
      members: [
        { userId: 1, name: "Sara Muller", profilePicture: "Null", role: null },
        {
          userId: 2,
          name: "Ossie Wilson",
          profilePicture: avatar8,
          role: "admin",
        },
        {
          userId: 3,
          name: "Jonathan Miller",
          profilePicture: "Null",
          role: null,
        },
        { userId: 4, name: "Paul Haynes", profilePicture: avatar7, role: null },
        { userId: 5, name: "Yana sha", profilePicture: avatar3, role: null },
        {
          userId: 6,
          name: "Steve Walker",
          profilePicture: avatar6,
          role: null,
        },
      ],
    },
    {
      gourpId: 2,
      name: "#Reporting",
      profilePicture: "Null",
      isGroup: true,
      unRead: 23,
      desc: "reporing Group here...",
      members: [
        { userId: 1, name: "Sara Muller", profilePicture: "Null", role: null },
        {
          userId: 2,
          name: "Ossie Wilson",
          profilePicture: avatar8,
          role: "admin",
        },
        {
          userId: 3,
          name: "Jonathan Miller",
          profilePicture: "Null",
          role: null,
        },
        { userId: 4, name: "Paul Haynes", profilePicture: avatar7, role: null },
        { userId: 5, name: "Yana sha", profilePicture: avatar3, role: null },
        {
          userId: 6,
          name: "Steve Walker",
          profilePicture: avatar6,
          role: null,
        },
      ],
    },
    {
      gourpId: 3,
      name: "#Designer",
      profilePicture: "Null",
      isGroup: true,
      unRead: 0,
      isNew: true,
      desc: "designers Group",
      members: [
        { userId: 1, name: "Sara Muller", profilePicture: "Null", role: null },
        {
          userId: 2,
          name: "Ossie Wilson",
          profilePicture: avatar8,
          role: "admin",
        },
        {
          userId: 3,
          name: "Jonathan Miller",
          profilePicture: "Null",
          role: null,
        },
        { userId: 4, name: "Paul Haynes", profilePicture: avatar7, role: null },
        { userId: 5, name: "Yana sha", profilePicture: avatar3, role: null },
        {
          userId: 6,
          name: "Steve Walker",
          profilePicture: avatar6,
          role: null,
        },
      ],
    },
    {
      gourpId: 4,
      name: "#Developers",
      profilePicture: "Null",
      isGroup: true,
      unRead: 0,
      desc: "developers Group",
      members: [
        { userId: 1, name: "Sara Muller", profilePicture: "Null", role: null },
        {
          userId: 2,
          name: "Ossie Wilson",
          profilePicture: avatar8,
          role: "admin",
        },
        {
          userId: 3,
          name: "Jonathan Miller",
          profilePicture: "Null",
          role: null,
        },
        { userId: 4, name: "Paul Haynes", profilePicture: avatar7, role: null },
        { userId: 5, name: "Yana sha", profilePicture: avatar3, role: null },
        {
          userId: 6,
          name: "Steve Walker",
          profilePicture: avatar6,
          role: null,
        },
      ],
    },
    {
      gourpId: 5,
      name: "#Project-aplha",
      profilePicture: "Null",
      isGroup: true,
      unRead: 0,
      isNew: true,
      desc: "project related Group",
      members: [
        { userId: 1, name: "Sara Muller", profilePicture: "Null", role: null },
        {
          userId: 2,
          name: "Ossie Wilson",
          profilePicture: avatar8,
          role: "admin",
        },
        {
          userId: 3,
          name: "Jonathan Miller",
          profilePicture: "Null",
          role: null,
        },
        { userId: 4, name: "Paul Haynes", profilePicture: avatar7, role: null },
        { userId: 5, name: "Yana sha", profilePicture: avatar3, role: null },
        {
          userId: 6,
          name: "Steve Walker",
          profilePicture: avatar6,
          role: null,
        },
      ],
    },
    {
      gourpId: 6,
      name: "#Snacks",
      profilePicture: "Null",
      isGroup: true,
      unRead: 0,
      desc: "snacks Group",
      members: [
        { userId: 1, name: "Sara Muller", profilePicture: "Null", role: null },
        {
          userId: 2,
          name: "Ossie Wilson",
          profilePicture: avatar8,
          role: "admin",
        },
        {
          userId: 3,
          name: "Jonathan Miller",
          profilePicture: "Null",
          role: null,
        },
        { userId: 4, name: "Paul Haynes", profilePicture: avatar7, role: null },
        { userId: 5, name: "Yana sha", profilePicture: avatar3, role: null },
        {
          userId: 6,
          name: "Steve Walker",
          profilePicture: avatar6,
          role: null,
        },
      ],
    },
  ],
  cases: [],
  contacts: [],
};

const Chat = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_MESSAGE_KEYWORD:
      return {
        ...state,
        messageKeyword: action.payload,
      };
    case CHAT_USER:
      return { ...state };

    case ACTIVE_USER:
      return {
        ...state,
        active_user: action.payload,
      };

    case ACTIVE_USER_TEMP:
      return {
        ...state,
        active_user_temp: action.payload,
      };
    case SET_MEMBER_INFO:
      return {
        ...state,
        memberInfo: action.payload,
      };
    case FULL_USER:
      return {
        ...state,
        users: action.payload,
      };

    case ADD_LOGGED_USER:
      const newUser = action.payload;
      return {
        ...state,
        users: [...state.users, newUser],
      };

    case CREATE_GROUP:
      const newGroup = action.payload;
      return {
        ...state,
        groups: [...state.groups, newGroup],
      };

    case SET_CASE_LIST:
      const caseList = action.payload;
      return {
        ...state,
        cases: [...state.cases, ...caseList],
      };
    case SET_CONTACT_LIST:
      const contactList = action.payload;
      return {
        ...state,
        contacts: [...state.contacts, ...contactList],
      };
    case SET_LOAD_MESSAGE:
      const newObj = action.payload;
      return {
        ...state,
        loadMessage: { ...newObj },
      };
    default:
      return { ...state };
  }
};

export default Chat;
